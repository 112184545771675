import React from 'react'
import logo1 from '../logo1.png';

export default function AwardWinning() {
    return (
        <section className="bg-gradient-to-br from-blue-600 to-blue-800 text-white py-20 px-8 md:px-16 lg:px-24 relative overflow-hidden">
            <div className="max-w-6xl mx-auto">
                <h2 className="text-4xl md:text-5xl font-bold mb-8 leading-tight">
                    Award-Winning Window & HVAC Service in Las Vegas
                </h2>
                <div className="flex flex-col md:flex-row items-center justify-between">
                    <div className="md:w-2/3 mb-12 md:mb-0">
                        <p className="text-lg mb-6 leading-relaxed">
                            At Leo's Windows and HVAC, we're committed to excellence. Our award-winning service sets the standard in Las Vegas, delivering personalized solutions that exceed expectations.
                        </p>
                        <blockquote className="italic text-blue-200 border-l-4 border-blue-300 pl-4 mb-6">
                            "We don't just meet standards; we set them. Experience the Leo's difference today."
                            <footer className="text-sm mt-2">- Leo, Owner</footer>
                        </blockquote>
                        <a href="#contact" className="inline-block bg-yellow-400 text-blue-900 font-semibold py-3 px-6 rounded-full hover:bg-yellow-300 transition duration-300 ease-in-out transform hover:scale-105">
                            Get Your Free Quote
                        </a>
                    </div>
                    <div className="md:w-1/3 relative">
                        <div className="absolute top-0 right-0 w-48 h-48 bg-contain bg-no-repeat bg-center" ></div>
                        <img src={logo1} alt="Leo's Windows and HVAC Team" className="rounded-lg shadow-2xl w-[50%] lg:pl-8" />
                    </div>
                </div>
            </div>
            <svg className="absolute bottom-0 left-0 w-full h-16 text-white" viewBox="0 0 1440 120" fill="currentColor" preserveAspectRatio="none">
                <path d="M0,96L48,101.3C96,107,192,117,288,112C384,107,480,85,576,85.3C672,85,768,107,864,112C960,117,1056,107,1152,96C1248,85,1344,75,1392,69.3L1440,64L1440,120L1392,120C1344,120,1248,120,1152,120C1056,120,960,120,864,120C768,120,672,120,576,120C480,120,384,120,288,120C192,120,96,120,48,120L0,120Z"></path>
            </svg>
        </section>
    )
}
