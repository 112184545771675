import React, { useState, useEffect, useRef } from 'react';

import './Navbar.css'; // We'll create this file for styling


const NavItem = ({ title, items }) => {
  const [isOpen, setIsOpen] = useState(false);
  const timeoutRef = useRef(null);

  const handleMouseEnter = () => {
    clearTimeout(timeoutRef.current);
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setIsOpen(false);
    }, 300); // 300ms delay before closing
  };

  return (
    <li
      className="relative group"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <a href={`#${title.toLowerCase()}`} className="hover:text-gray-300 py-2
      inline-block px-4 py-2 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75 transition-colors duration-200
      ">{title}</a>

      {items && (
        <ul className={`
          absolute left-0 bg-white shadow-md py-2 rounded-md lg:w-48
          transition-all duration-300 origin-top-left
          ${isOpen ? 'scale-100 opacity-100' : 'scale-95 opacity-0 pointer-events-none'}
        `}>
          {items.map((item, index) => (
            <li key={index}>
              <a
                href={item.link}
                className="block px-4 py-2 text-sm  text-blue-600 "
                onClick={() => setIsOpen(false)}
              >
                {item.name}
              </a>
            </li>
          ))}
        </ul>
      )}
    </li>
  );
};

const Navbar = () => {
  const [sticky, setSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setSticky(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const navItems = [
    { title: 'Home', items: null },
    {
      title: 'About', items: [
        { name: 'Our Story', link: '#our-story' },
        { name: 'Team', link: '#team' },
        { name: 'Mission', link: '#mission' },
      ]
    },
    {
      title: 'Services', items: [
        { name: 'Windows', link: '#windows' },
        { name: 'HVAC', link: '#hvac' },
        { name: 'Maintenance', link: '#maintenance' },
      ]
    },
    {
      title: 'Contact', items: [
        { name: 'Get a Quote', link: '#quote' },
        { name: 'Support', link: '#support' },
        { name: 'Locations', link: '#locations' },
      ]
    },
  ];

  return (
    <nav className={`z-50 ${sticky ? 'sticky' : 'invis'} bg-blue-600 text-white w-full top-0`}>
      <div className="container mx-auto px-4">
        <div className="flex sm:flex-row lg:flex-row items-center justify-between py-4">

          <div className={`${sticky ? 'logo font-bold mb-4 lg:mb-0 ' : 'noshow'}`}>
            <div className='font-semibold text-[0.5rem] sm:text-[0.7rem] md:text-2xl lg:text-3xl  xl:text-4xl 2xl:text:6xl '>
              Call today! 702-861-4035
            </div>



          </div>
          <div className='gap-here'></div>
          <ul className="nav-links flex sm:flex-row lg:flex-row space-y-2 lg:space-y-0 lg:space-x-6">
            {navItems.map((item, index) => (
              <NavItem key={index} title={item.title} items={item.items} />
            ))}
          </ul>
        </div>
        <div className="flex flex-col lg:flex-row items-center justify-center gap-4 py-4 text-sm">
          <div>
          </div>
          <div className={`text-3xl rounded-full ${sticky ? `text-xl` : '  lg:text-8xl sm:text-4xl text-black'}`}>
            <div className='font-semibold'>
              <div className={`px-4 py-2 ${sticky ? 'bg-blue-500' : 'bg-none'} rounded-[5rem] shadow-md transform hover:scale-105 transition-transform duration-300 ease-in-out`}>
                <div className={`${sticky ? '' : 'flex items-center justify-center '}`}>
                  <div className='flex space-x-4'>
                    <span className="font-bold text-white">The Energy Savers</span>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;