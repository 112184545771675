import React from 'react';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  const footerLinks = [
    { title: 'Company', items: ['About Us', 'Our Team', 'Careers', 'News'] },
    { title: 'Services', items: ['Windows', 'HVAC', 'Maintenance', 'Installation'] },
    { title: 'Support', items: ['FAQ', 'Contact Us', 'Terms of Service', 'Privacy Policy'] },
  ];

  return (
    <footer className="bg-gray-800 text-white">
      <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {/* Company Info */}
          <div>
            <h2 className="text-2xl font-bold mb-4">Windows and HVAC</h2>
            <p className="mb-2">Serving the Las Vegas Valley, NV & surrounding areas.</p>
            <p className="font-semibold">Call us: 702-861-4035</p>
          </div>

          {/* Footer Links */}
          {footerLinks.map((section, index) => (
            <div key={index}>
              <h3 className="text-lg font-semibold mb-4">{section.title}</h3>
              <ul>
                {section.items.map((item, itemIndex) => (
                  <li key={itemIndex} className="mb-2">
                    <a href="#" className="hover:text-gray-300 transition-colors duration-200">
                      {item}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        {/* Social Media and Newsletter */}
        <div className="mt-8 pt-8 border-t border-gray-700">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="mb-4 md:mb-0">
              <h3 className="text-lg font-semibold mb-2">Follow Us</h3>
              <div className="flex space-x-4">
                <a href="#" className="hover:text-gray-300 transition-colors duration-200">Facebook</a>
                <a href="#" className="hover:text-gray-300 transition-colors duration-200">Twitter</a>
                <a href="#" className="hover:text-gray-300 transition-colors duration-200">Instagram</a>
              </div>
            </div>
            <div>
              <h3 className="text-lg font-semibold mb-2">Subscribe to Our Newsletter</h3>
              <form className="flex">
                <input
                  type="email"
                  placeholder="Enter your email"
                  className="px-4 py-2 w-full md:w-auto rounded-l-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-800"
                />
                <button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-600 px-4 py-2 rounded-r-lg transition-colors duration-200"
                >
                  Subscribe
                </button>
              </form>
            </div>
          </div>
        </div>

        {/* Copyright */}
        <div className="mt-8 text-center text-sm">
          <p>&copy; {currentYear} Windows and HVAC. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;