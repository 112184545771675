import React from 'react'

export default function Para1() {
    return (
        <div className="bg-gradient-to-b from-blue-50 to-white py-16">
            <div className="container mx-auto px-4">
                <h2 className="text-4xl font-bold text-center text-gray-800 mb-12" id="about">
                    Save Energy, Save Money
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
                    <div className="space-y-6">
                        <div className="bg-white p-6 rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-300">
                            <h3 className="text-2xl font-semibold text-blue-600 mb-4">Efficient Solutions</h3>
                            <p className="text-gray-700">
                                Save hundreds of dollars 💵💵 each month with our energy-efficient air conditioners and upgraded windows.
                            </p>
                        </div>
                        <div className="bg-white p-6 rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-300">
                            <h3 className="text-2xl font-semibold text-blue-600 mb-4">Customer-First Approach</h3>
                            <p className="text-gray-700">
                                We prioritize your needs, offering honest, dependable service with no hidden fees or price haggling.
                            </p>
                        </div>
                    </div>
                    <div className="space-y-6">
                        <div className="bg-blue-600 text-white p-6 rounded-lg shadow-lg">
                            <h3 className="text-2xl font-semibold mb-4">Why Choose Us?</h3>
                            <ul className="list-disc list-inside space-y-2">
                                <li>Top-notch treatment from start to finish</li>
                                <li>Growing loyal customer base</li>
                                <li>Commitment to integrity</li>
                                <li>No tardy or disgruntled technicians</li>
                            </ul>
                        </div>
                        <button className="w-full bg-yellow-400 hover:bg-yellow-500 text-gray-800 font-bold py-3 px-6 rounded-lg transition duration-300 ease-in-out transform hover:scale-105">
                            Stop Wasting Money - Call Us Today! 📞
                        </button>
                    </div>
                </div>
            </div>
            <div className='para1-bg-img h-64 w-full mt-16 rounded-lg shadow-xl'></div>
        </div>
    )
}
