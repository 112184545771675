import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';

export default function Modal   ({ isOpen, onClose, children }) {
  useEffect(() => {
    const blurElements = () => {
      document.body.querySelectorAll('.blur-target').forEach(el => {
        el.style.filter = isOpen ? 'blur(17px)' : 'none';
        el.style.transition = 'filter 0.3s ease';
      });
    };

    blurElements();

    return () => {
      // Reset blur on unmount
      document.body.querySelectorAll('.blur-target').forEach(el => {
        el.style.filter = 'none';
      });
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full">
        <div className="flex  justify-between items-center mb-4">
          <h2 className="text-xl font-bold text-center">Ways to Contact</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X size={24} />
          </button>
        </div>
     
        {children}
      </div>
    </div>
  );
};