import React from 'react'

import { motion } from 'framer-motion';

const ServiceTag = ({ tag, icon }) => {
    return (
        <motion.div
            whileHover={{ scale: 1.05 }}
            className=' bg-white shadow-lg rounded-lg p-6 flex items-center space-x-4 transition-all duration-300 hover:shadow-xl'
        >
            <div className="text-3xl text-blue-600">{icon}</div>
            <h3 className='text-lg font-semibold text-gray-800'>{tag}</h3>
        </motion.div>
    )
}

export default function ServicesDetail() {
    const services = [
        { tag: "Glass Replacement", icon: "🪟" },
        { tag: "Window Pane Replacement", icon: "🔧" },
        { tag: "Whole Window Replacement", icon: "🏠" },
        { tag: "Full House Renovation", icon: "🏗️" },
        { tag: "Custom Windows", icon: "✨" },
        { tag: "Dust Repair", icon: "🧹" },
        { tag: "Air Duct Replacement", icon: "💨" },
        { tag: "Whole AC Duct Replacement", icon: "❄️" },
        { tag: "Full AC Replacement", icon: "🌡️" },
        { tag: "Reduce Heating/Cooling Bill", icon: "💰" },
        { tag: "Sound Proofing", icon: "🔇" },
        { tag: "Wall Color Matching", icon: "🎨" },
        { tag: "Custom Projects", icon: "🛠️" },
        { tag: "And More!", icon: "➕" },
    ];

    return (
        <section className='bg-gradient-to-b from-blue-50 to-white py-24' id='services'>
            <div className="container mx-auto px-4">
                <h2 className='text-4xl font-bold text-center text-gray-800 mb-12'>
                    Our Services
                </h2>
                <p className='text-xl text-center text-gray-600 mb-16 max-w-3xl mx-auto'>
                    We offer a wide range of services to meet all your window and HVAC needs. Satisfaction guaranteed or your money back!
                </p>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {services.map((service, index) => (
                        <ServiceTag key={index} tag={service.tag} icon={service.icon} />
                    ))}
                </div>
            </div>
        </section>
    )
}
