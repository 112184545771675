import React from 'react'

export default function
    () {
    return (
        <div className='blur-target flex flex-col items-center justify-center min-h-screen bg-gradient-to-b from-blue-500 to-blue-700 text-white py-20'>
            <div className='container mx-auto px-4'>
                <h1 className='text-5xl md:text-6xl font-bold mb-12 text-center leading-tight'>
                    Expert Window Solutions <br className='hidden md:inline' />
                    for the Las Vegas Valley
                </h1>
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8'>
                    {[
                        { title: 'Window Repair', icon: '🔧' },
                        { title: 'Window Replacement', icon: '🪟' },
                        { title: 'Free Estimates', icon: '📋' },
                        { title: 'Best Prices in Town', icon: '💰' }
                    ].map((service, index) => (
                        <div key={index} className='bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-xl p-6 transition-all duration-300 hover:transform hover:scale-105 hover:shadow-xl'>
                            <div className='text-5xl mb-4'>{service.icon}</div>
                            <h3 className='text-xl font-semibold mb-2'>{service.title}</h3>
                            <p className='text-sm opacity-80'>Professional service tailored to your needs</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
