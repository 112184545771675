import React, { useState } from 'react';
import './HeroSection.css';
import Modal from './Modal';
import Navbar from './Navbar';
// import Navbar from './NavbarMob';





const HeroSection = () => {

  const [isModalOpen, setIsModalOpen] = useState(false);


  return (
    <div>
      <section className="hero-section blur-target" id='contact'>
        <video
          className="absolute top-0 left-0 min-w-full min-h-full object-cover"
          autoPlay
          loop
          muted
          playsInline
        >

          <source src="/acS_good.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <Navbar />
        

        <div className="hero-content ">
          <h1>Call Today</h1>
          <button
            onClick={() => setIsModalOpen(true)}
            className=" bg-primary-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Contact Now
          </button>

        </div>
      </section>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <p>Call 702-861-4035 <br></br>or inquire with email: windowservices@leoambo.com</p>
      </Modal>
    </div>
    //   <section className="relative h-screen w-full overflow-hidden">


    //   <div className="relative z-10 flex flex-col items-center justify-center h-full text-white">
    //     <h1 className="text-4xl md:text-6xl font-bold mb-4">Welcome to My Website</h1>
    //     <p className="text-xl md:text-2xl">Discover amazing things</p>
    //   </div>
    // </section>
  );
};

export default HeroSection;