import logo from './logo.svg';
import './App.css';
import { useState } from 'react';
import Draggable from './comp/Drag';
import FazebukPost from './comp/FazebukPost';
import FazebukSlide from './comp/FazebukSlide';
import HomeDepotNav from './comp/HomeDepotNav';
import Navbar from './comp/Navbar';
import HeroSection from './comp/Hero';
import Services from './comp/Services';
import Responsive from './comp/Responsive';
import Para1 from './comp/Para1';
import AwardWinning from './comp/AwardWinning';
import ServicesDetail from './comp/ServicesDetail';
import Footer from './comp/Footer';
import PreFooter from './comp/PreFooter';
import ModernTitleOverlay from './comp/Title';



function App() {

  const [visible, setVisible] = useState(false);

  const toggleFeedBackForm = () => {
    setVisible(!visible);
  }


  return (
    <div className="App ">

      <div className='blur-target'>
        {/* <Navbar /> */}
      </div>

      <HeroSection />

      {/* <Responsive /> */}
      <Services />
      {/* the home depot sticky search bar */}
      {/* <HomeDepotNav /> */}
      {/* the facebook scrolling sections  */}
      <Para1 />
      <AwardWinning />
      <ServicesDetail />
      <PreFooter />
      <Footer />

      
     



    </div>
  );
}

export default App;
