import React from 'react';

const PreFooter = () => {
  const features = [
    { title: 'Expert Installation', description: 'Precision and care in every project' },
    { title: 'Energy Efficiency', description: 'Cutting-edge solutions for your home' },
    { title: '24/7 Support', description: 'Always here when you need us' },
    { title: 'Custom Solutions', description: 'Tailored to your unique needs' },
  ];

  return (
    <section className="bg-gradient-to-br from-blue-50 to-green-50 py-20">
      <div className="container mx-auto px-4">
        <div className="mb-16 text-center">
          <h2 className="text-4xl font-bold mb-4 text-gray-800">Elevate Your Home Comfort</h2>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">Experience the perfect blend of innovation and reliability with our windows and HVAC solutions.</p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center mb-20">
          <div className="space-y-8">
            {features.map((feature, index) => (
              <div key={index} className="flex items-start">
                <div className="flex-shrink-0 w-12 h-12 rounded-full bg-blue-100 flex items-center justify-center mr-4">
                  <svg className="w-6 h-6 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                </div>
                <div>
                  <h3 className="text-xl font-semibold mb-2 text-gray-800">{feature.title}</h3>
                  <p className="text-gray-600">{feature.description}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="relative">
            <svg className="w-full h-auto" viewBox="0 0 400 300" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M50 150 Q 100 50 150 150 T 250 150 T 350 150" stroke="#3B82F6" strokeWidth="8" fill="none" />
              <circle cx="50" cy="150" r="20" fill="#3B82F6" />
              <circle cx="150" cy="150" r="20" fill="#3B82F6" />
              <circle cx="250" cy="150" r="20" fill="#3B82F6" />
              <circle cx="350" cy="150" r="20" fill="#3B82F6" />
              <rect x="175" y="50" width="50" height="80" rx="10" fill="#34D399" />
              <path d="M180 90 L195 75 L210 90" stroke="white" strokeWidth="3" />
              <rect x="175" y="170" width="50" height="80" rx="10" fill="#34D399" />
              <path d="M180 210 L195 225 L210 210" stroke="white" strokeWidth="3" />
            </svg>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-xl p-8 md:p-12 relative overflow-hidden">
          <div className="absolute top-0 right-0 w-40 h-40 bg-yellow-200 rounded-full -mr-20 -mt-20 transform rotate-45"></div>
          <div className="relative z-10">
            <h3 className="text-3xl font-bold mb-4 text-gray-800">Ready for a Comfort Revolution?</h3>
            <p className="text-xl text-gray-600 mb-8">Let's create the perfect climate for your home. Schedule your free consultation today!</p>
            <a
              href="#"
              className="inline-block bg-blue-600 text-white font-semibold px-8 py-4 rounded-lg hover:bg-blue-700 transition-colors duration-200 shadow-md hover:shadow-lg transform hover:-translate-y-1"
            >
              Get Your Free Consultation
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PreFooter;